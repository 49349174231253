import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { LogoutService } from './logout.service';
import { ManageCookieService } from './manage-cookie.service';
declare let moment: any;

@Injectable()
export class UserSessionService {
  constructor(private loginService: LoginService, private manageCookieService: ManageCookieService, private logoutService: LogoutService) {}

  sessionData: any;
  lastUserActionDate: any = null;
  checkInterval: number = 600; // 10 min in seconds
  dateCheckpoint: any;
  enableSessionExtender: boolean = false;
  sessionKillerTimeout: any;

  startSessionManager() {
    // Start Activity listeners
    document.addEventListener(
      'click',
      (event) => {
        this.activityListener();
      },
      false
    );

    document.addEventListener(
      'keyup',
      (event) => {
        this.activityListener();
      },
      false
    );

    document.addEventListener(
      'dragstart',
      (event) => {
        this.activityListener();
      },
      false
    );

    document.addEventListener(
      'drag',
      (event) => {
        this.activityListener();
      },
      false
    );
  }

  extendSession() {
    const sessionId = this.manageCookieService.getCookie('scode') || sessionStorage.getItem('scode');
    this.loginService.loginRequest(sessionId).subscribe(
      (result) => {
        if (!result) {
          this.logoutService.logout('logout');
        } else {
          this.manageCookieService.setCookie('scode', sessionId, { period: 'd', value: 7 });
          this.sessionData = result;
          const expireAfter = Math.ceil(this.sessionData.expire - new Date().getTime() / 1000);
          // console.log(this.sessionData);
          // console.log('Current time      : ', moment.unix(new Date().getTime() / 1000).format('DD/MM/YY,  HH:mm:ss A'));
          // console.log('Session expire at : ', moment.unix(this.sessionData.expire).format('DD/MM/YY,  HH:mm:ss A'));
          // console.log('Expires after     : ', expireAfter + ' seconds');
          // console.log('Session set Time  :' + this.sessionData.time_limit + ' seconds');
          // console.log('---------------------------------------------------------------');

          // On every refresh reset activity and session trackers
          this.dateCheckpoint = new Date().getTime();
          this.lastUserActionDate = new Date().getTime();
          this.sessionKillerSetter(expireAfter);

          // If first call enable session extender
          if (!this.enableSessionExtender) {
            this.enableSessionExtender = true;
            let sessionCheckInterval = this.sessionData.time_limit < this.checkInterval ? this.sessionData.time_limit : this.checkInterval;
            sessionCheckInterval -= 5; // Check 5 seconds earlier
            this.startSessionExtender(sessionCheckInterval);
          }
        }
      },
      (error) => {
        // Redirect to Login portal
        this.logoutService.logout('logout');
      }
    );
  }

  startSessionExtender(checkInterval) {
    //Check interval comes in second
    setInterval(() => {
      // Extend session with last activity period
      if (this.dateCheckpoint !== this.lastUserActionDate) {
        this.extendSession();
      }
    }, checkInterval * 1000);
  }

  sessionKillerSetter(timeout) {
    // Receive timeout in second !
    const _t = this;
    const safeTimeout = timeout - 3;

    clearTimeout(_t.sessionKillerTimeout);

    this.sessionKillerTimeout = setTimeout(() => {
      _t.logoutService.logout('logout');
    }, safeTimeout * 1000);
  }

  activityListener() {
    this.lastUserActionDate = new Date().getTime();
  }
}
