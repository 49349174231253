import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DeviceInfo } from 'ngx-device-detector';
import { promoBannersConfig, promoConfig } from '../../../../configs/promo-banner-configs';
import { IRefsMap } from '../../../../models/common.int';
import { AdvanceDeviceDetectionService } from '../../../../services/advance-device-detection.service';
import { LoginService } from '../../../../services/login.service';
import { ManageCookieService } from '../../../../services/manage-cookie.service';
import { UserPlansDataService } from '../../../../services/user-plans-data.service';

@Component({
  selector: 'app-promo-banner',
  templateUrl: './promo-banner.component.html',
  styleUrls: ['./promo-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoBannerComponent implements OnInit, OnDestroy {
  constructor(
    private manageCookieService: ManageCookieService,
    private deviceService: AdvanceDeviceDetectionService,
    private loginService: LoginService,
    private userPlansDataService: UserPlansDataService,
    private cdRef: ChangeDetectorRef
  ) {}

  @Input() pageLocation: any;

  isMobile: boolean;
  showContent: boolean = false;
  sharedPages: string[] = ['shared-folder', 'sharelink'];
  noBannerPages: string[] = ['survey', 'pricing', 'purchase-success', 'home', 'privacy-policy', 'terms-of-use', 'checkout'];
  bannerData: promoConfig;
  deviceData: DeviceInfo = this.deviceService.getDeviceInfo();
  isAndroid: boolean;
  isAndroidAppInstalled: boolean;

  refs: IRefsMap = {};
  innerHeight: number = window.innerHeight;
  screenData: any = '';
  bannerUIClass: string = '';

  ngOnInit() {
    this.innerHeight = window.innerHeight;
    this.isMobile = document.getElementsByTagName('html')[0].classList.contains('mobile-device');
    this.isAndroid = this.deviceData.os === 'Android';
    this.loginService.getLoggedResult().then(({ loggedUser }) => {
      if (loggedUser) {
        this.refs.getMDSubscriptions = this.userPlansDataService.getMDSubscriptions().subscribe((data) => {
          this.initBanner(data);
        });
      } else {
        this.initBanner();
      }
    });
  }

  async getRelatedApps() {
    const relatedApps = await (window.navigator as any).getInstalledRelatedApps();
    relatedApps.forEach((app) => {      
      if (app.id === 'com.mobisystems.mobidrive') {
        this.isAndroidAppInstalled = true;
      }
    });
  }

  initBanner(data?) {
    // Show Banner with small delay
    setTimeout(() => {
      if (this.isAndroid) {
        this.getRelatedApps();
      }
      for (let i = 0; i < promoBannersConfig.length; i++) {
        const hasCookie = this.manageCookieService.getCookie(promoBannersConfig[i].cookie.name);
        if (!hasCookie && this.platformSupported(promoBannersConfig[i].data)) {
          this.bannerData = promoBannersConfig[i];
          break;
        }
      }
      const isShowOfficeSuiteBanner =
        this.loginService.getProfileData()?.planFeatures.storageTier === 0 &&
        !data?.inTrial &&
        !this.isMobile &&
        !this.manageCookieService.getCookie('OfficeSuite_Promo_Banner_First');
      const isShowLicenseFullestBanner =
        this.loginService.getProfileData()?.planFeatures.storageTier === 3 && data?.inTrial && !this.isMobile;
      this.showContent = !isShowOfficeSuiteBanner && !isShowLicenseFullestBanner && !this.isAndroidAppInstalled;
      this.cdRef.detectChanges();
    }, 1500);
  }

  ngOnDestroy() {
    // Clean refs
    for (const ref in this.refs) {
      if (this.refs[ref]) {
        this.refs[ref].unsubscribe();
      }
    }
  }

  bannerClosed(bannerCookie) {
    this.manageCookieService.setCookie(bannerCookie.name, `${bannerCookie.state}`, { period: 'd', value: bannerCookie.days });
  }

  platformSupported(banData) {
    if (banData.supportedOS.length && banData.supportedOS.indexOf(this.deviceData.os.toLowerCase()) !== -1) {
      return true;
    }

    if (banData.notSupportedOS.length && banData.notSupportedOS.indexOf(this.deviceData.os.toLowerCase()) !== -1) {
      return false;
    }

    return true;
  }
}
