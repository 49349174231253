import { Injectable, EventEmitter, Output } from '@angular/core';
import { ServerDetectionService } from './server-detection.service';
import { getCookieValue } from '../helpers/get-cookie-value';
export interface ICookieTiming {
  period: 's' | 'm' | 'h' | 'd';
  value: number;
}
@Injectable()
export class ManageCookieService {
  @Output() getCookieState: EventEmitter<boolean> = new EventEmitter();
  @Output() getCookieData: EventEmitter<any> = new EventEmitter();

  constructor(private serverDetection: ServerDetectionService) {}

  server: boolean = this.serverDetection.isServer();

  setCookie(cname: string, cvalue: string, expire: ICookieTiming) {
    if (!this.server) {
      const d = new Date();
      let timeInterval: number;
      if (expire.period === 's') {
        timeInterval = expire.value;
      }
      if (expire.period === 'm') {
        timeInterval = expire.value * 60;
      }
      if (expire.period === 'h') {
        timeInterval = expire.value * 60 * 60;
      }
      if (expire.period === 'd') {
        timeInterval = expire.value * 24 * 60 * 60;
      }

      d.setTime(d.getTime() + timeInterval * 1000);
      const expires: string = 'expires=' + d.toUTCString();
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    }
  }

  getCookie(cname): string {
    if (!this.server) {
      return getCookieValue(cname, decodeURIComponent(document.cookie));
    }

    return '';
  }

  deleteCookie(name: string) {
    if (!this.server) {
      document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
  }
}
