import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthError } from '../helpers/errors';
import { LogoutService } from './logout.service';
import { ManageCookieService } from './manage-cookie.service';
import { ServerDetectionService } from './server-detection.service';

declare global {
  interface Window {
    imprint: any;
  }
}

export interface IUserAuthData {
  token: string;
  accountId: string;
  scode: string;
}

window.imprint = window.imprint || {};
@Injectable()
export class AuthService {
  private apiUrl: string = environment.apiUrl;
  private downloadUrl: string = environment.downloadUrl;
  pushTkn: string = this.manageCookieService.getCookie('pushtkn2');
  server: boolean = this.serverDetection.isServer();
  serverAuthData: IUserAuthData = null;

  constructor(
    private http: HttpClient,
    private manageCookieService: ManageCookieService,
    private serverDetection: ServerDetectionService,
    private logoutService: LogoutService
  ) {}

  getXcode(scode) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<{ xcode: string }>(environment.accountsLink + '/api/auth/xcode?' + `sessionId=${scode}`, header).pipe(
      map((res) => {
        if (res) {
          return res;
        } else {
          this.handleErrors(res);
        }
      })
    );
  }

  getPlans(data) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    const plans = [];
    for (const pln in data.plans.listPlans) {
      plans.push(data.plans.listPlans[pln].inAppId);
    }
    for (const pln in data.plans.additional) {
      plans.push(data.plans.additional[pln]);
    }

    const discount = {};
    if (this.manageCookieService.getCookie('discountCode')) {
      discount['discountCode'] = this.manageCookieService.getCookie('discountCode');
      discount['timeZoneOffset'] = this.manageCookieService.getCookie('timeZoneOffset');
    }

    const inApps = plans.toString();
    return this.http
      .get(environment.storeApi + '/api/prices', {
        headers: header.headers,
        params: {
          inAppIds: inApps,
          additionalInfo: '1',
          ...discount,
        },
      })
      .pipe(
        map((res: HttpResponse<any>) => {
          if (res) {
            return res;
          } else {
            this.handleErrors(res);
          }
        })
      );
  }

  getOfficeSuitOnlineLink(payload, lang, path = 'files', command = 'get-open-document-link') {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: lang,
        app: 'com.mobisystems.web.mobidrive',
        pushtkn: this.pushTkn ? this.pushTkn : 'web',
      }),
    };

    let requestParams = `path=${path}&command=${command}`;

    for (const obj in payload) {
      requestParams = `${requestParams}&${JSON.stringify(obj).replace(/"/g, '')}=${encodeURIComponent(JSON.stringify(payload[obj]))}`;
    }
    return this.http.post(environment.apiUrl, requestParams, header).pipe(
      map((res: any) => {
        if (res?.error) {
          this.handleErrors(res);
        }
        return res;
      })
    );
  }

  sendEmail(mailsData, lang: string, path: string, command: string) {
    const authData = this.getUserAuth();
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: lang,
        app: 'com.mobisystems.web.mobidrive',
        pushtkn: this.pushTkn ? this.pushTkn : 'web',
        tkn: authData.token != null ? authData.token : '',
        account: authData.accountId != null ? authData.accountId : '',
      }),
    };

    let requestedParams = `path=${path}&command=${command}`;
    for (const obj in mailsData) {
      requestedParams = `${requestedParams}&${JSON.stringify(obj).replace(/"/g, '')}=${encodeURIComponent(JSON.stringify(mailsData[obj]))}`;
    }

    return this.http.post(environment.apiUrl, requestedParams, header).pipe(
      map((res: any) => {
        if (res?.error) {
          this.handleErrors(res);
        }
        return res;
      })
    );
  }

  authRequest(path: string, command: string, data: any, aid?, application?, setInstallationId?): Observable<any> {
    const authData = aid ? { token: '', accountId: aid } : this.getUserAuth();
    // Set Headers
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        app: 'com.mobisystems.web.mobidrive',
        pushtkn: this.pushTkn ? this.pushTkn : 'web',
        tkn: authData.token != null ? authData.token : '', // Error code: "invalidToken"
        account: authData.accountId != null ? authData.accountId : '', // Error code: "accountNotFound"
      }),
    };

    // Set Body Data
    let requestedParams = `path=${path}&command=${command}`;

    if (application) {
      requestedParams += `&application=${application}`;
    }
    if (setInstallationId) {
      header.headers.set('installation-id', null);
    }

    for (const obj in data) {
      requestedParams = `${requestedParams}&${JSON.stringify(obj).replace(/"/g, '')}=${encodeURIComponent(JSON.stringify(data[obj]))}`;
    }

    // Set Request Error Timeout in second
    let callTimeout = 300; // default is 5min
    if (path === 'files' && command === 'get-thumb') {
      callTimeout = 7; // Thumbnails
    }
    // Make Call
    return this.http.post(this.apiUrl, requestedParams, header).pipe(
      timeout(callTimeout * 1000),
      map((res: HttpResponse<any>) => {
        if (!res['error']) {
          let response = res;
          const data = res['result'];

          if (data) {
            const trimData = data.trim();
            response = JSON.parse(trimData);
          }

          return response;
        } else {
          this.handleErrors(res, command);
        }
      })
    );
  }

  downloadRequest(command, resType, items: any, taskId?): Observable<any> {
    // let authData = aid ? {token: '', accountId: aid} : this.getUserAuth();
    const authData = this.getUserAuth();
    const apiUrl = `${this.downloadUrl}/${command}`;

    const stringifyObj = { ids: items };
    const requestedParams = items ? JSON.stringify(stringifyObj) : '';

    // Make Call
    return this.http
      .post(apiUrl, requestedParams, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          app: 'com.mobisystems.web.mobidrive',
          pushtkn: this.pushTkn ? this.pushTkn : 'web',
          tkn: authData.token != null ? authData.token : '', // Error code: "invalidToken"
          account: authData.accountId != null ? authData.accountId : '', // Error code: "accountNotFound"
          taskId: taskId ? taskId : '',
        }),
        responseType: resType,
      })
      .pipe(
        map((res) => {
          if (res) {
            return res;
          } else {
            this.handleErrors(res);
          }
        })
      );
  }

  setUserAuth(data, sessionId) {
    if (data.token) {
      if (!this.server) {
        sessionStorage.setItem('scode', sessionId);
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('accountId', data.aid ? data.aid : data.accountId);
      } else {
        this.serverAuthData = {
          scode: sessionId,
          token: data.token,
          accountId: data.aid ? data.aid : data.accountId,
        };
      }
    }
  }

  getUserAuth(): IUserAuthData {
    if (!this.server) {
      return {
        token: sessionStorage.getItem('token'),
        accountId: sessionStorage.getItem('accountId'),
        scode: sessionStorage.getItem('scode'),
      };
    } else {
      return {
        token: this.serverAuthData ? this.serverAuthData.token : '',
        accountId: this.serverAuthData ? this.serverAuthData.accountId : '',
        scode: this.serverAuthData ? this.serverAuthData.scode : '',
      };
    }
  }

  handleErrors(error, command?: string) {
    console.log('%c HANDLE ERROR in this.authRequest.js', 'background: #222; color: #bada55');
    console.log(JSON.stringify(error));
    console.log(error['code']);
    if (error && error['code'].match(/tokenNotFound|invalidToken/gi) !== null) {
      if (command !== 'validate-key') {
        this.logoutService.logout('error');
      } else {
        throw new Error(error['code'].replace(/"/g, ''));
      }
    } else if (error.retryAfter) {
      throw new AuthError(error['code'].replace(/"/g, ''), error['retryAfter'], error['error']);
    } else {
      throw new Error(error['code'].replace(/"/g, ''));
    }
  }
}
