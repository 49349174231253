import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TrackingService} from '../../../../services/tracking.service';
import {OfficeSuiteSupportService} from '../../../../services/office-suite-support.service';
import {ManageCookieService} from '../../../../services/manage-cookie.service';

@Component({
  selector: 'app-os-last-version',
  templateUrl: './os-last-version.component.html',
  styleUrls: ['./os-last-version.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OSLastVersionComponent {
  @Input() modal: any;
  @Input() menuSource: string = '';

  constructor(private trackingService: TrackingService,
              private manageCookieService: ManageCookieService,
              private officeSuiteSupportService: OfficeSuiteSupportService) {}

  download() {
    window.open('https://www.officesuite.com/free-download?referrer=createnewMDWEB', '_blank');
  }

  goToApp() {
    this.manageCookieService.setCookie(
      'is_show_OfficeSuite_last_version_banner_' + this.menuSource, `${true}`, { period: 'd', value: 3650 }
    );
    const appCode = this.officeSuiteSupportService.isOfficeSupported({name: '.doc'}, true);
    if (appCode) {
      this.officeSuiteSupportService.openNewModuleWithApp(this.menuSource, appCode);
      this.trackingService.trackEvent(
        'web-app.usage',
        'upload-button.side-bar',
        'create-new-' + (this.menuSource === 'presentation' ? 'slide' : this.menuSource)
      );
    }
    this.modal.close();
  }
}
