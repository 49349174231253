<section class="modal-wrap">
  <div class="modal-body cfix">
    <div class="view-box cfix new-item">
      <img class="promo-os-trial-image" src="assets/images/promo-os-trial.svg?1" alt="promo-os-trial">
      <p class="promo-os-trial-title">{{'modal.doMore' | translate}}</p>
      <p class="promo-os-trial-text" [innerHTML]="'modal.getPremium' | translate"></p>
      <button class="button col-blue medium-size sub-modal-button promo-os-trial-button" (click)="startTrial()">{{ 'modal.tryDaysFree' | translate }}</button>
    </div>
  </div>
</section>
