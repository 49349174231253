<section class="modal-wrap">
  <div class="modal-body cfix">
    <div class="view-box cfix os-last-version">
      <div class="os-last-version-header">
        <img class="os-last-version-image" src="assets/images/modules/{{menuSource}}.svg" alt="module">
        <h5 class="os-last-version-title" data-qa="im-title">{{ 'modal.' + menuSource | translate }}</h5>
      </div>
      <div class="os-last-version-body">
        <div class="os-last-version-text" [innerHTML]="'modal.lastOSVersion' | translate : { item: ('modal.' + menuSource) | translate }"></div>
        <button type="button"
                class="button col-blue medium-size sub-modal-button os-last-version-button"
                (click)="download()"
        >
          {{ 'modal.downloadOS' | translate }}
        </button>
        <p class="os-last-version-link" (click)="goToApp()">{{ 'modal.alreadyHaveOS' | translate }}</p>
      </div>
    </div>
  </div>
</section>
