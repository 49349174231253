import { Injectable } from '@angular/core';

import { loadRecaptchaScript } from '../helpers/load-script';
import { environment } from '../../environments/environment';

interface RecaptchaEnterpriseType {
  ready: (callback: () => void) => void;
  execute: (site_key: string, { action: string }) => Promise<string>;
}

declare global {
  interface Window {
    grecaptcha: {
      enterprise: RecaptchaEnterpriseType;
    };
  }
}

type RecaptchaActions = 'invite_friends';

@Injectable()
export class RecaptchaEnterpriseService {

  private script: HTMLScriptElement;

  constructor() {
    this.init();
  }

  public execute(action: RecaptchaActions) {
    return new Promise<string>((resolve, reject) => {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise.execute(environment.recaptchaSiteKey, { action })
          .then((token) => resolve(token))
          .catch(reject);
      });
    });
  }

  private init() {
    // loading js script
    this.script = loadRecaptchaScript(environment.recaptchaSiteKey);
  }
}
