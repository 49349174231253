import { Injectable, EventEmitter, Output } from '@angular/core';
import { ApplyActionService } from './apply-action.service';
import { OfficeSuiteSupportService } from './office-suite-support.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { UploadDataService } from './upload/upload-data.service';
import { AuthService } from './auth.service';
import { LoginService } from './login.service';
import { ManageCookieService } from './manage-cookie.service';
import { Router } from '@angular/router';

export interface IActionMenuItem {
  nameCode: string;
  groupName: string;
  action: string;
  showForMulti: boolean;
  alwaysVisible: boolean;
  topMenu: boolean;
  contextMenu: boolean;
  icon: string;
  menuSeparator?: boolean;
}

export type IActionMenu = Array<IActionMenuItem>;

export interface IFilterPreset {
  backupRoot: boolean;
  backupForbidden: string[];
  canCopy: boolean;
  hasSharedFiles: boolean;
  otherOwnerFiles: boolean;
}

@Injectable()
export class ActionMenuService {
  @Output() getActionMenuData: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private ngxModal: NgxSmartModalService,
    private applyActionService: ApplyActionService,
    private manageCookieService: ManageCookieService,
    private officeSuiteSupportService: OfficeSuiteSupportService,
    private deviceService: DeviceDetectorService,
    private uploadDataService: UploadDataService,
    private authService: AuthService,
    private router: Router,
    private loginService: LoginService
  ) {}

  deviceData: any = this.deviceService.getDeviceInfo();

  filesSelected: number;
  actionMenu: IActionMenu = [];
  defaultMenu: IActionMenu = [
    {
      nameCode: 'uploadFolder',
      groupName: 'upload-group',
      action: 'upload-folder',
      showForMulti: false,
      alwaysVisible: false,
      topMenu: false,
      contextMenu: true,
      icon: 'iconame-upload-folder',
    },
    {
      nameCode: 'uploadFiles',
      groupName: 'upload-group',
      action: 'upload-file',
      showForMulti: false,
      alwaysVisible: false,
      topMenu: false,
      contextMenu: true,
      icon: 'iconame-upload-file',
    },
    {
      nameCode: 'newFolder',
      groupName: 'folder-group',
      action: 'create-folder',
      showForMulti: true,
      alwaysVisible: true,
      topMenu: true,
      contextMenu: false,
      icon: 'iconame-new-folder',
    },
    {
      nameCode: 'delete',
      groupName: 'delete-group',
      action: 'delete',
      showForMulti: true,
      alwaysVisible: false,
      topMenu: true,
      contextMenu: true,
      icon: 'iconame-delete',
    },
    {
      nameCode: 'deletePermanent',
      groupName: 'delete-group',
      action: 'delete-permanent',
      showForMulti: true,
      alwaysVisible: false,
      topMenu: true,
      contextMenu: true,
      icon: 'iconame-delete',
    },
    {
      nameCode: 'remove',
      groupName: 'delete-group',
      action: 'remove-recent',
      showForMulti: true,
      alwaysVisible: false,
      topMenu: true,
      contextMenu: true,
      icon: 'iconame-remove',
    },
    {
      nameCode: 'restore',
      groupName: 'restore-group',
      action: 'restore',
      showForMulti: true,
      alwaysVisible: false,
      topMenu: true,
      contextMenu: true,
      icon: 'iconame-restore',
    },
    {
      nameCode: 'properties',
      groupName: 'action-group',
      action: 'file-properties',
      showForMulti: false,
      alwaysVisible: false,
      topMenu: true,
      contextMenu: true,
      icon: 'iconame-file-properties',
    },
    {
      nameCode: 'fileHistory',
      groupName: 'action-group',
      action: 'version-history',
      showForMulti: false,
      alwaysVisible: false,
      topMenu: true,
      contextMenu: true,
      icon: 'iconame-version-history',
    },
    {
      nameCode: 'move',
      groupName: 'action-group',
      action: 'move',
      showForMulti: true,
      alwaysVisible: false,
      topMenu: true,
      contextMenu: true,
      icon: 'iconame-move',
    },
    {
      nameCode: 'copy',
      groupName: 'action-group',
      action: 'copy',
      showForMulti: true,
      alwaysVisible: false,
      topMenu: true,
      contextMenu: true,
      icon: 'iconame-copy',
    },
    {
      nameCode: 'renameFile',
      groupName: 'action-group',
      action: 'rename',
      showForMulti: false,
      alwaysVisible: false,
      topMenu: true,
      contextMenu: true,
      icon: 'iconame-rename',
    },
    {
      nameCode: 'download',
      groupName: 'action-group',
      action: 'download',
      showForMulti: true,
      alwaysVisible: false,
      topMenu: true,
      contextMenu: true,
      icon: 'iconame-download-file',
    },
    {
      nameCode: 'unshare',
      groupName: 'share-group',
      action: 'unshare',
      showForMulti: true,
      alwaysVisible: false,
      topMenu: true,
      contextMenu: true,
      icon: 'iconame-stop-sharing',
    },
    {
      nameCode: 'share',
      groupName: 'share-group',
      action: 'share',
      showForMulti: false,
      alwaysVisible: false,
      topMenu: true,
      contextMenu: true,
      icon: 'iconame-share-file',
    },
    {
      nameCode: 'openWithPdfExtra',
      groupName: 'office-group',
      action: 'openWithPdfExtra',
      showForMulti: false,
      alwaysVisible: false,
      topMenu: true,
      contextMenu: true,
      icon: 'iconame-pdf-extra',
    },
    {
      nameCode: 'openWithOffice',
      groupName: 'office-group',
      action: 'openWithOffice',
      showForMulti: false,
      alwaysVisible: false,
      topMenu: true,
      contextMenu: true,
      icon: 'iconame-officesuite',
    },
  ];

  setMenuConfig(menuConfig) {
    this.actionMenu = this.defaultMenu.filter((item) => {
      return menuConfig.indexOf(item.action) === -1;
    });
  }

  getDefaultMenu() {
    return this.filterMenu([]);
  }

  filterMenu(data, outsideMenu?: string[]): IActionMenu {
    this.filesSelected = data.length;
    const filterPreset: IFilterPreset = {
      backupRoot: this.filesSelected && data[0].parent && data[0].parent.key === 'BA__CK__UPS' ? true : false,
      backupForbidden: ['delete', 'create-folder', 'share', 'unshare'],
      canCopy: this.loginService.isLoggedIn(),
      hasSharedFiles: false,
      otherOwnerFiles: false,
    };

    for (let i = 0; i < this.filesSelected; i++) {
      if (data[i].publiclyShared && !filterPreset.hasSharedFiles) {
        filterPreset.hasSharedFiles = true;
      }

      if (data[i].owner && !filterPreset.otherOwnerFiles) {
        filterPreset.otherOwnerFiles = true;
      }

      if (filterPreset.otherOwnerFiles && filterPreset.hasSharedFiles) {
        break;
      }
    }

    // Return filtered Menu
    if (this.filesSelected > 1) {
      return this.menuForMultipleItems(filterPreset, data);
    }

    if (this.filesSelected === 1) {
      return this.menuForSingleItem(filterPreset, data);
    }

    if (outsideMenu) {
      return this.menuNoFiles(outsideMenu);
    }

    return this.actionMenu.filter((item) => {
      return item.alwaysVisible === true;
    });
  }

  menuForMultipleItems(filterPreset: IFilterPreset, data): IActionMenu {
    const menuFiltered: IActionMenu = this.actionMenu.filter((item) => {
      if (item.action === 'copy') {
        return filterPreset.canCopy;
      }
      if (item.action === 'move' && this.checkBackupStructureItem(data)) {
        return false;
      }

      if (item.showForMulti === true) {
        if (filterPreset.backupRoot && filterPreset.backupForbidden.indexOf(item.action) !== -1) {
          return false;
        } else if (!filterPreset.hasSharedFiles) {
          return item.action !== 'unshare';
        } else if (filterPreset.hasSharedFiles && filterPreset.otherOwnerFiles) {
          return item.action !== 'unshare';
        }
        return true;
      }
    });

    return menuFiltered;
  }

  menuForSingleItem(filterPreset: IFilterPreset, data): IActionMenu {
    let filter: string[] = ['upload-file', 'upload-folder'];

    if (filterPreset.backupRoot) {
      filter = filter.concat(filterPreset.backupForbidden);
    }

    if (!filterPreset.canCopy) {
      filter.push('copy');
    }

    if (this.checkBackupStructureItem(data)) {
      filter.push('move');
    }

    if (!data[0].dir) {
      // If File
      if (
        !this.loginService.isLoggedIn() ||
        (data[0].account !== this.loginService.getProfileData().accountId && !this.router.url.startsWith('/shared'))
      ) {
        // if not logged in or another owner on the shared page
        filter = filter.concat(['version-history', 'share']);
      }

      if (!data[0].publiclyShared || data[0].owner) {
        filter.push('unshare');
      }

      if (data[0].owner) {
        filter.push('rename');
      }

      if (!this.officeSuiteSupportService.isOfficeSupported(data[0]) || this.deviceData.browser === 'opera') {
        filter.push('openWithOffice');
      }

      if (!this.officeSuiteSupportService.isPDFExtraSupported(data[0]) || this.deviceData.browser === 'opera') {
        filter.push('openWithPdfExtra');
      }
    } else {
      // If Folder
      filter.push('openWithOffice', 'openWithPdfExtra', 'version-history');

      if (typeof data[0].publiclyShared === 'undefined') {
        filter.push('share');
      }

      if (!data[0].publiclyShared) {
        filter.push('unshare');
      }
    }

    return this.actionMenu.filter((item) => {
      return filter.indexOf(item.action) === -1;
    });
  }

  menuNoFiles(outsideMenu): IActionMenu {
    const outMenu = [].concat(outsideMenu);

    if (this.uploadDataService.canUploadFolder() && outMenu.indexOf('upload-folder') !== -1) {
      outMenu.push('upload-folder');
    }

    return this.actionMenu.filter((item) => {
      return outMenu.indexOf(item.action) !== -1;
    });
  }

  performAction(actionName: string, selectedFiles: any[], menuSource?: string) {
    let source: string = menuSource ? 'breadcrumb' : '';

    if (actionName === 'newItem') {
      source = menuSource;
    }

    if (actionName === 'download') {
      this.applyActionService.downloadAction(selectedFiles);
      return;
    }

    if (actionName === 'openWithOffice' || actionName === 'openWithPdfExtra') {
      this.applyActionService.downloadAction(selectedFiles, actionName);
      return;
    }

    if (actionName === 'unshare') {
      selectedFiles.forEach((file) => {
        if (file.publiclyShared) {
          this.applyActionService.manageSharableLink([file], false, null);
        }
      });
      return;
    }

    if (actionName === 'upload-file' || actionName === 'upload-folder') {
      this.uploadDataService.cleanInput();

      const simulateClick = function (elem) {
        // Create our event (with options)
        const evt = new MouseEvent('click', {
          bubbles: false,
          cancelable: true,
        });

        elem.dispatchEvent(evt);
      };

      const inputId = actionName === 'upload-file' ? 'uplFile' : 'uplFolder';
      const uplInputElem = document.getElementById(inputId);
      simulateClick(uplInputElem);

      return;
    }

    if (actionName === 'delete') {
      this.applyActionService.deleteAction(selectedFiles, menuSource);
      return;
    }

    this.ngxModal.setModalData({ view: actionName, files: selectedFiles, menuSource: source }, 'actionModal');
    this.ngxModal.resetModalData('actionModal');
    this.ngxModal.getModal('actionModal').open();
  }

  checkBackupStructureItem(data): boolean {
    return data.find((item) => this.isBackupStructureItem(item));
  }

  isBackupStructureItem(item): boolean {
    if (item.parent && item.parent.key === 'BA__CK__UPS') {
      return true;
    } else if (!item.parent) {
      return false;
    }
    return this.isBackupStructureItem(item.parent);
  }
}
