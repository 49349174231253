import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AuthService } from './auth.service';

@Injectable()
export class SendEmailService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  sendInvites(mailsData) {
    return this.http.post(window.location.origin + '/mdapi/invite-friends', mailsData).pipe(
      map((res: HttpResponse<any>) => {
        if (res) {
          return res;
        } else {
          this.authService.handleErrors(res);
        }
      })
    );
  }
}
