<div class="logo-area {{ view + '-view' }}" [ngClass]="{ static: staticPage }">
  <ng-container *ngIf="view === 'regular' || staticPage">
    <ng-container *ngIf="staticPage">
      <div class="mobisystems-logo" *ngIf="!noMSlogo.includes(pageName)">
        <a target="_blank" href="https://www.mobisystems.com/" tabindex="0" (click)="trackEvent()">
          <ng-container *ngTemplateOutlet="mobisystemsLogoImg"></ng-container>
        </a>
      </div>
      <a *ngIf="!(pageName === 'pricing-plans' && hasProfileData)" [routerLink]="['/']" [queryParams]="{ vp: 'true' }" tabindex="0">
        <ng-container *ngTemplateOutlet="mobidriveLogoImg"></ng-container>
      </a>
      <a *ngIf="pageName === 'pricing-plans' && hasProfileData" [routerLink]="['/my-files']" tabindex="0">
        <ng-container *ngTemplateOutlet="mobidriveLogoImg"></ng-container>
      </a>
    </ng-container>

    <a *ngIf="!staticPage" [routerLink]="['/my-files']" tabindex="0">
      <ng-container *ngTemplateOutlet="mobidriveLogoImg"></ng-container>
    </a>
  </ng-container>

  <ng-container *ngIf="view === 'mobile' && !staticPage">
    <a [routerLink]="['/my-files']" tabindex="0">
      <img src="../../../../assets/images/drive-logo-mobile.svg?1" alt="MobiDrive Logo" />
    </a>
  </ng-container>
</div>

<ng-template #mobisystemsLogoImg>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg">
    <g fill="none">
      <path
        d="m0 4v52c0 2.209139 1.790861 4 4 4h51.9994c2.209139 0 4-1.790861 4-4v-52c0-2.209139-1.790861-4-4-4h-51.9994c-2.209139 0-4 1.790861-4 4z"
        fill="#ed1c24"
      />
      <path
        d="m41.545926 35.8595122c-1.0712088 1.0668293-1.0712088 2.5814634-1.0712088 2.5814634v2.8185366c0 1.5131707 1.5131556 1.5131707 1.5131556 1.5131707h7.0374906c1.5131556 0 1.5131556-1.5131707 1.5131556-1.5131707v-12.8809756c0-1.5146342-1.0668186-.4448781-1.0668186-.4448781zm-31.302126-7.4809756v12.8809756s0 1.5131707 1.5116922 1.5131707h7.038954s1.5116922 0 1.5116922-1.5131707v-2.8185366s0-1.5117073-1.068282-2.5814634l-7.924311-7.9258537s-1.0697454-1.0697561-1.0697454.4448781m43.8259032-10.817561h-7.0594416s-1.5116922 0-2.5799742 1.0668293l-5.860917 5.8609756c-1.068282 1.0682927-1.0653552 1.0756097-1.0653552 1.0756097l.0058536.0043903-5.099949 5.1h-.0321948l-.9424296.942439s-1.0697454 1.0682927-2.1380274 0l-.943893-.942439h-.0014634l-4.0287402-4.0287805c-1.068282-1.0697561-1.0638918-1.0741463-1.0638918-1.0741463l.0014634-.0029269s.0029268-.0029268-.5004828-.504878l-2.0458332-2.0473171s-1.068282-1.0668293-2.582901-1.0668293h-7.0550514s-1.5131556 0-.4419468 1.0668293l12.6335322 12.6321951-.0058536.0058537s-.0058536.0087805 1.0624284 1.0756097l2.3429034 2.34h-.0204876l2.6399736 2.6414635c1.068282 1.0697561 2.1394908 0 2.1394908 0l1.858518-1.86h.0321948l4.1897142-4.1882927-.0043902-.0087805s-.0058536-.0058537 1.0653552-1.0726829l15.9452064-15.9453659c-.0014634-.0014634 1.0712088-1.0697561-.4434102-1.0697561"
        fill="#fff"
      />
    </g>
  </svg>
</ng-template>

<ng-template #mobidriveLogoImg>
  <svg
    alt="MobiDrive Logo"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 262 48.5"
    style="enable-background: new 0 0 262 48.5"
    xml:space="preserve"
  >
    <style type="text/css">
      .st2mdl {
        fill: url(#SVGID_1_);
      }
      .st3mdl {
        fill: url(#SVGID_2_);
      }
      .st4mdl {
        fill: url(#SVGID_3_);
      }
    </style>
    <g>
      <g>
        <g>
          <path
            style="fill: #2b2b2b"
            d="M102.1,37.6L102.1,37.6l8.8-22.6h6.4v29.4h-4.1V19.8h-0.1l-9.8,24.6h-2.6L91,19.8h-0.1v24.6h-4V15h6.4
          L102.1,37.6z"
          />
          <path
            style="fill: #2b2b2b"
            d="M140,34.5c0,1.5-0.3,2.9-0.8,4.2c-0.5,1.3-1.3,2.4-2.2,3.3c-1,0.9-2.1,1.6-3.4,2.2c-1.3,0.5-2.7,0.8-4.2,0.8
          c-1.5,0-2.9-0.3-4.2-0.8c-1.3-0.5-2.4-1.2-3.4-2.2c-0.9-0.9-1.7-2-2.2-3.3c-0.5-1.3-0.8-2.7-0.8-4.2c0-1.5,0.3-2.9,0.8-4.2
          c0.5-1.3,1.3-2.3,2.2-3.3c0.9-0.9,2.1-1.6,3.4-2.1c1.3-0.5,2.7-0.8,4.2-0.8c1.5,0,2.9,0.3,4.2,0.8c1.3,0.5,2.4,1.2,3.4,2.1
          s1.7,2,2.2,3.3C139.7,31.6,140,33,140,34.5z M135.9,34.5c0-0.9-0.1-1.8-0.4-2.7c-0.3-0.9-0.7-1.6-1.3-2.3
          c-0.6-0.6-1.2-1.2-2.1-1.6c-0.8-0.4-1.8-0.6-2.8-0.6c-1.1,0-2,0.2-2.8,0.6c-0.8,0.4-1.5,0.9-2.1,1.6c-0.6,0.6-1,1.4-1.3,2.3
          c-0.3,0.9-0.4,1.8-0.4,2.7s0.1,1.8,0.4,2.7c0.3,0.9,0.7,1.6,1.3,2.3c0.6,0.7,1.2,1.2,2.1,1.6c0.8,0.4,1.8,0.6,2.8,0.6
          c1.1,0,2-0.2,2.8-0.6c0.8-0.4,1.5-0.9,2.1-1.6c0.6-0.7,1-1.4,1.3-2.3C135.8,36.4,135.9,35.5,135.9,34.5z"
          />
          <path
            style="fill: #2b2b2b"
            d="M145.4,41.3v3.1h-3.8V13h3.9v14.7h0.1c0.7-1,1.6-1.8,2.8-2.5c1.2-0.7,2.6-1,4.2-1c1.5,0,2.8,0.3,4,0.8
          c1.2,0.5,2.2,1.3,3.1,2.2c0.9,0.9,1.5,2,2,3.3c0.5,1.3,0.7,2.6,0.7,4.1c0,1.4-0.2,2.8-0.7,4.1c-0.5,1.3-1.1,2.4-2,3.3
          c-0.9,0.9-1.9,1.7-3.1,2.2c-1.2,0.5-2.6,0.8-4.1,0.8c-1.4,0-2.7-0.3-4-0.9C147.2,43.4,146.2,42.5,145.4,41.3L145.4,41.3z
            M158.4,34.5c0-0.9-0.1-1.8-0.4-2.7c-0.3-0.9-0.7-1.6-1.2-2.3c-0.6-0.7-1.2-1.2-2.1-1.6c-0.8-0.4-1.8-0.6-2.8-0.6
          c-1,0-1.9,0.2-2.7,0.6c-0.8,0.4-1.5,0.9-2.1,1.6c-0.6,0.7-1,1.4-1.4,2.3c-0.3,0.9-0.5,1.8-0.5,2.7c0,0.9,0.2,1.8,0.5,2.7
          c0.3,0.9,0.8,1.6,1.4,2.3c0.6,0.6,1.3,1.2,2.1,1.6c0.8,0.4,1.7,0.6,2.7,0.6c1.1,0,2-0.2,2.8-0.6c0.8-0.4,1.5-0.9,2.1-1.6
          c0.6-0.7,1-1.4,1.2-2.3C158.3,36.3,158.4,35.4,158.4,34.5z"
          />
          <path
            style="fill: #2b2b2b"
            d="M170,17.6c0,0.7-0.3,1.3-0.8,1.8c-0.5,0.5-1.1,0.7-1.8,0.7s-1.3-0.2-1.8-0.7c-0.5-0.5-0.7-1.1-0.7-1.8
          c0-0.7,0.2-1.3,0.7-1.8c0.5-0.5,1.1-0.7,1.8-0.7s1.3,0.2,1.8,0.7C169.7,16.3,170,16.9,170,17.6z M169.4,44.4h-3.9V24.7h3.9V44.4z
          "
          />
          <path
            style="fill: #2b2b2b"
            d="M173.4,15h10.1c1.9,0,3.8,0.3,5.7,0.8c1.9,0.6,3.6,1.4,5.1,2.6c1.5,1.2,2.8,2.7,3.7,4.6
          c0.9,1.9,1.4,4.1,1.4,6.7c0,2.5-0.5,4.7-1.4,6.5c-0.9,1.8-2.2,3.4-3.7,4.6c-1.5,1.2-3.2,2.1-5.1,2.7c-1.9,0.6-3.8,0.9-5.7,0.9
          h-10.1V15z M177.5,40.8h5.2c1.8,0,3.4-0.2,4.9-0.7c1.5-0.5,2.8-1.2,3.9-2.2c1.1-0.9,1.9-2.1,2.6-3.5c0.6-1.4,0.9-3,0.9-4.7
          c0-1.8-0.3-3.4-0.9-4.8c-0.6-1.4-1.5-2.5-2.6-3.5c-1.1-0.9-2.4-1.6-3.9-2.1c-1.5-0.5-3.2-0.7-4.9-0.7h-5.2V40.8z"
          />
          <path
            style="fill: #2b2b2b"
            d="M201.4,26.9c0-0.8-0.1-1.6-0.1-2.2h3.7c0.1,0.5,0.1,1.1,0.1,1.7c0,0.7,0,1.2,0,1.6h0.1
          c0.6-1.2,1.4-2.1,2.4-2.8c1.1-0.7,2.3-1.1,3.6-1.1c0.6,0,1.1,0.1,1.5,0.2l-0.2,3.6c-0.6-0.1-1.1-0.2-1.8-0.2
          c-0.9,0-1.8,0.2-2.5,0.5c-0.7,0.3-1.3,0.8-1.7,1.4c-0.5,0.6-0.8,1.3-1,2c-0.2,0.8-0.3,1.6-0.3,2.4v10.3h-3.9V28.9
          C201.5,28.4,201.4,27.7,201.4,26.9z"
          />
          <path
            style="fill: #2b2b2b"
            d="M219.4,17.6c0,0.7-0.3,1.3-0.8,1.8c-0.5,0.5-1.1,0.7-1.8,0.7s-1.3-0.2-1.8-0.7c-0.5-0.5-0.7-1.1-0.7-1.8
          c0-0.7,0.2-1.3,0.7-1.8c0.5-0.5,1.1-0.7,1.8-0.7s1.3,0.2,1.8,0.7C219.2,16.3,219.4,16.9,219.4,17.6z M218.8,44.4h-3.9V24.7h3.9
          V44.4z"
          />
          <path style="fill: #2b2b2b" d="M232.4,44.4h-4.2l-8-19.7h4.3l5.8,15.5h0.1l5.8-15.5h4.2L232.4,44.4z" />
          <path
            style="fill: #2b2b2b"
            d="M259.1,34.4c0,0.2,0,0.4,0,0.7c0,0.2,0,0.4,0,0.7h-15.7c0,0.8,0.2,1.6,0.6,2.3c0.3,0.7,0.8,1.4,1.4,1.9
          c0.6,0.5,1.3,1,2,1.3c0.8,0.3,1.6,0.5,2.4,0.5c1.3,0,2.5-0.3,3.4-0.9c1-0.6,1.7-1.3,2.3-2.1l2.7,2.2c-1.1,1.4-2.3,2.4-3.7,3.1
          c-1.4,0.7-3,1-4.8,1c-1.5,0-2.9-0.2-4.2-0.7c-1.3-0.5-2.4-1.2-3.3-2.1c-0.9-0.9-1.6-2-2.2-3.3c-0.5-1.3-0.8-2.7-0.8-4.2
          c0-1.5,0.3-2.9,0.8-4.2c0.5-1.3,1.2-2.4,2.1-3.3c0.9-0.9,2-1.6,3.2-2.1c1.2-0.5,2.6-0.8,4-0.8c1.4,0,2.8,0.2,3.9,0.7
          c1.2,0.5,2.2,1.1,3,2c0.8,0.9,1.5,2,1.9,3.2C258.9,31.4,259.1,32.8,259.1,34.4z M255.1,32.8c0-0.8-0.2-1.5-0.4-2.2
          s-0.6-1.3-1-1.8c-0.5-0.5-1-0.9-1.7-1.2c-0.7-0.3-1.5-0.4-2.4-0.4c-0.8,0-1.6,0.1-2.3,0.4c-0.7,0.3-1.4,0.7-1.9,1.2
          c-0.5,0.5-1,1.1-1.3,1.8c-0.3,0.7-0.5,1.4-0.6,2.2H255.1z"
          />
        </g>
      </g>
      <g>
        <path
          style="fill: #0d7eb9"
          d="M21.5,37.3C21.5,37.3,21.5,37.3,21.5,37.3l1.4,1.4l3.4,3.4l1.4,1.4l0.7,0.7c0.5,0.5,1.4,0.5,1.9,0l0.7-0.7
        l0.7-0.7l0,0l3.5-3.5c0,0,0.1-0.2,0.8-0.9l4.7-4.7c0,0,0,0,0,0l16-17.2c0.7-0.7,1.6-1.4,1-2.3C54,7.5,46.7,3,38.5,3
        C31,3,24.4,6.7,20.4,12.4c0,0-0.1,0.1-0.1,0c-0.8-0.1-1.7-0.2-2.6-0.2c-5.3,0-10.2,2.2-13.6,5.8c-0.5,0.5-0.5,1.4,0,1.9L21.5,37.3
        z"
        />
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="69.1126" y1="37.6899" x2="29.0901" y2="26.9659">
          <stop offset="0" style="stop-color: #47e046" />
          <stop offset="0.1869" style="stop-color: #3ed248" />
          <stop offset="0.5394" style="stop-color: #26ae4c" />
          <stop offset="1" style="stop-color: #007552" />
        </linearGradient>
        <path
          class="st2mdl"
          d="M48.4,48.4H32c-1,0-1.6-1.3-0.8-2l1.2-1.2c0,0,0,0,0.7-0.8l0,0l28.2-28.2c0.8-0.8,2-0.9,2.9-0.3
        c4.4,2.9,6.9,8,6.7,12.9c-0.4,11.1-9.8,19.7-20.8,19.7H48.4z"
        />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="0.1283" y1="35.1022" x2="27.8169" y2="35.1022">
          <stop offset="2.397487e-03" style="stop-color: #f95645" />
          <stop offset="0.5012" style="stop-color: #e22f26" />
          <stop offset="1" style="stop-color: #c90404" />
        </linearGradient>
        <path
          class="st3mdl"
          d="M27.2,46.3l-1-1l-0.7-0.7l0,0L3.1,22.2c-0.6-0.6-1.8-0.4-2,0.5c-0.6,2-1,4.1-0.9,6.4
        c0.1,9.8,6.5,19.3,17.8,19.3l0,0h8.8c0.9,0,1.4-1.1,0.7-1.8L27.2,46.3z"
        />
        <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="47.4144" y1="0.6693" x2="23.8521" y2="41.4804">
          <stop offset="2.414341e-03" style="stop-color: #28c6ff" />
          <stop offset="0.3758" style="stop-color: #1faee8" />
          <stop offset="1" style="stop-color: #0d7eb9" />
        </linearGradient>
        <path
          class="st4mdl"
          d="M60.5,11C56.1,4.5,48.6,0.4,40.2,0.5C31.6,0.7,24.1,5.4,20,12.4c-2.1,3.5-3.3,7.5-3.3,11.7
        c-0.1,3.7,0.7,7.2,2.1,10.4l2.7,2.7c0,0,0,0,0,0l1.4,1.4l3.4,3.4l1.4,1.4l0.3,0.3c0.6,0.6,1.6,0.7,2.3,0.2l27.3-27.3l2.6-2.6
        C61.1,13.3,61.2,11.9,60.5,11z"
        />
      </g>
    </g>
  </svg>
</ng-template>
